var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper companies"
  }, [_vm.managerNewCompany ? _c('newCompany', {
    attrs: {
      "id": "new-company"
    },
    on: {
      "callback": function callback() {
        _vm.callbackModals('managerNewCompany');
      }
    }
  }) : _vm._e(), _vm.managerEditCompany ? _c('editCompany', {
    on: {
      "callback": function callback() {
        _vm.callbackModals('managerEditCompany');
      }
    }
  }) : _vm._e(), _vm.managerDeleteCompany ? _c('delete-company', {
    on: {
      "company-deleted": function companyDeleted($event) {
        return _vm.reloadData();
      },
      "callback": function callback() {
        _vm.callbackModals('managerDeleteCompany');
      }
    }
  }) : _vm._e(), _vm.managerAccessControl ? _c('accessControl', {
    on: {
      "callback": function callback() {
        _vm.callbackModals('managerAccessControl');
      }
    }
  }) : _vm._e(), _c('header', [_c('section', [_c('div', {
    staticClass: "text"
  }, [_c('h1', {
    staticClass: "text-uppercase"
  }, [_vm._v(" " + _vm._s(_vm.$t('company.title')) + " ")]), _c('p', [_vm._v(_vm._s(_vm.$t('company.subTitle')))])]), _c('img', {
    attrs: {
      "src": "/img/logo.svg"
    }
  })]), _vm.getterCompanies.length ? _c('div', {
    staticClass: "search-companies"
  }, [_c('div', {
    staticClass: "search"
  }, [_c('img', {
    attrs: {
      "src": "/img/icon-search.svg"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search,
      expression: "search"
    }],
    staticClass: "search-content",
    attrs: {
      "placeholder": _vm.$t('company.filterCompany.filterCompany')
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.search = $event.target.value;
      }
    }
  }), _vm.search.length ? _c('button', {
    staticClass: "btn-close",
    on: {
      "click": function click($event) {
        _vm.search = '';
      }
    }
  }, [_c('svgLoader', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.right",
      value: {
        content: _vm.$t('company.filterCompany.clearFilter'),
        offset: 10
      },
      expression: "{ content: $t('company.filterCompany.clearFilter'), offset: 10 }",
      modifiers: {
        "right": true
      }
    }],
    staticClass: "icon-close",
    attrs: {
      "src": "/img/icon-close.svg",
      "color": "#fff"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "icons"
  }, [_c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.top",
      value: {
        content: _vm.$t('company.filterCompany.expandCard'),
        offset: 2
      },
      expression: "{ content: $t('company.filterCompany.expandCard'), offset: 2 }",
      modifiers: {
        "top": true
      }
    }],
    on: {
      "click": function click($event) {
        return _vm.changeSizeCard(false, false);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/max.svg"
    }
  })]), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.top",
      value: {
        content: _vm.$t('company.filterCompany.packCard'),
        offset: 2
      },
      expression: "{ content: $t('company.filterCompany.packCard'), offset: 2 }",
      modifiers: {
        "top": true
      }
    }],
    on: {
      "click": function click($event) {
        return _vm.changeSizeCard(true, false);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/compact.svg"
    }
  })]), _c('button', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip.top",
      value: {
        content: _vm.$t('company.filterCompany.minimizeCard'),
        offset: 2
      },
      expression: "{ content: $t('company.filterCompany.minimizeCard'), offset: 2 }",
      modifiers: {
        "top": true
      }
    }],
    on: {
      "click": function click($event) {
        return _vm.changeSizeCard(true, true);
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "/img/min.svg"
    }
  })])])]) : _vm._e()]), _c('div', {
    staticClass: "wrapper-content-company"
  }, [_vm.isLoading && _vm.getterCompanies.length === 0 ? _c('loading') : _vm._e(), !_vm.isLoading && (_vm.getterCompanies.length === 0 || _vm.filteredCompanies.length === 0) ? _c('section', {
    staticClass: "no-company"
  }, [_c('img', {
    attrs: {
      "src": "/img/icon-no-company.svg",
      "alt": ""
    }
  }), _c('h2', {
    staticClass: "text-uppercase"
  }, [_vm._v(" " + _vm._s(_vm.getterCompanies.length === 0 ? _vm.$t('company.withoutCompanies.title') : _vm.$t('company.emptyFilteredCompanies')) + " ")])]) : _vm._e(), _vm.filteredCompanies.length !== 0 ? _c('div', {
    ref: "containerCarousel",
    staticClass: "container-carousel"
  }, [_c('ul', {
    staticClass: "carousel"
  }, _vm._l(_vm.filteredCompanies, function (company, index) {
    return _c('li', {
      key: index,
      staticClass: "card",
      class: {
        'compact': _vm.compact,
        'min': _vm.min
      }
    }, [_c('div', {
      staticClass: "card-content",
      class: {
        'min': _vm.min
      }
    }, [_c('div', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip.top",
        value: {
          content: _vm.min ? company.name : "",
          offset: 10
        },
        expression: "{ content: min ? company.name : ``, offset: 10 }",
        modifiers: {
          "top": true
        }
      }],
      staticClass: "logo",
      class: "".concat(company.logo ? 'with-logo' : 'without-logo', " ").concat(_vm.min ? 'min' : ''),
      on: {
        "click": function click($event) {
          return _vm.checkGoToBots(company);
        }
      }
    }, [company.logo ? _c('img', {
      attrs: {
        "src": company.logo
      }
    }) : _c('img', {
      attrs: {
        "src": "/img/logo-simple.svg",
        "alt": ""
      }
    })]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [!_vm.min ? _c('div', {
      staticClass: "content"
    }, [_c('h1', {
      staticClass: "title"
    }, [_vm._v(" " + _vm._s(company.name) + " ")]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [!_vm.compact ? _c('p', {
      staticClass: "description"
    }, [_vm._v(" " + _vm._s(company.description || '-') + " ")]) : _vm._e()])], 1) : _vm._e()]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [!_vm.min ? _c('div', {
      staticClass: "content see-bots-container"
    }, [_c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [company.hasOwnProperty('bots') && company.bots.length !== 0 && !_vm.compact ? _c('ul', {
      staticClass: "bots-list"
    }, [_vm._l(company.bots.slice(0, 4), function (bot, indexBots) {
      return _c('li', {
        directives: [{
          name: "tooltip",
          rawName: "v-tooltip.auto",
          value: {
            content: company.bots[indexBots].name,
            offset: 10
          },
          expression: "{ content: company.bots[indexBots].name, offset: 10 }",
          modifiers: {
            "auto": true
          }
        }],
        key: indexBots,
        class: {
          'compact': _vm.compact
        }
      }, [_c('div', {
        staticClass: "container-logo-bot",
        class: {
          'compact': _vm.compact
        },
        style: {
          'borderColor': company.bots[indexBots].botColor
        }
      }, [_c('div', {
        staticClass: "logo-bot",
        class: {
          'compact': _vm.compact
        },
        style: {
          'backgroundColor': company.bots[indexBots].botColor
        }
      }, [company.bots[indexBots].hasOwnProperty('logo') && company.bots[indexBots].logo !== null ? _c('img', {
        attrs: {
          "src": company.bots[indexBots].logo
        }
      }) : _c('img', {
        staticClass: "no-bot-logo",
        attrs: {
          "src": "/img/icon-bot.svg",
          "alt": ""
        }
      })])])]);
    }), company.bots.length > 4 ? _c('li', {
      staticClass: "counter"
    }, [_vm._v(" " + _vm._s("+ ".concat(company.bots.length - 4)) + " ")]) : _vm._e()], 2) : _vm._e()]), _c('button', {
      staticClass: "see-bots",
      on: {
        "click": function click($event) {
          return _vm.goToBots(company);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('company.tooltip.seeBots')) + " ")])], 1) : _vm._e()]), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [!_vm.min ? _c('div', {
      staticClass: "action-buttons"
    }, [company.isAdmin ? _c('button', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip.auto",
        value: {
          content: _vm.$t('company.tooltip.manageCompany'),
          offset: 10
        },
        expression: "{ content: $t('company.tooltip.manageCompany'), offset: 10 }",
        modifiers: {
          "auto": true
        }
      }],
      on: {
        "click": function click($event) {
          return _vm.openModalAccessControl(company);
        }
      }
    }, [_c('svgLoader', {
      attrs: {
        "src": "/img/icon-configuration-v2.svg",
        "color": "#878787"
      }
    })], 1) : _vm._e(), _c('button', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip.auto",
        value: {
          content: _vm.$t('company.tooltip.editCompany'),
          offset: 10
        },
        expression: "{ content: $t('company.tooltip.editCompany'), offset: 10 }",
        modifiers: {
          "auto": true
        }
      }],
      on: {
        "click": function click($event) {
          return _vm.openModalEditCompany(company);
        }
      }
    }, [_c('svgLoader', {
      attrs: {
        "src": "/img/edit-icon.svg",
        "color": "#878787"
      }
    })], 1), _c('button', {
      directives: [{
        name: "tooltip",
        rawName: "v-tooltip.auto",
        value: {
          content: _vm.$t('company.tooltip.deleteCompany'),
          offset: 10
        },
        expression: "{ content: $t('company.tooltip.deleteCompany'), offset: 10 }",
        modifiers: {
          "auto": true
        }
      }],
      on: {
        "click": function click($event) {
          return _vm.openModalDeleteCompany(company);
        }
      }
    }, [_c('svgLoader', {
      attrs: {
        "src": "/img/delete-icon.svg",
        "color": "#878787"
      }
    })], 1)]) : _vm._e()])], 1)]);
  }), 0)]) : _vm._e()], 1), _c('addButton', {
    attrs: {
      "id": "btn-add",
      "on-click": function onClick() {
        return _vm.openNewCompany();
      },
      "show-message": _vm.getterCompanies.length === 0 && !_vm.isLoading && !_vm.$getterStartGuideNavigation,
      "title": _vm.$t('company.buttons.clickAddCompany')
    }
  }), _c('logout')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }