import { mapGetters, mapActions, mapMutations } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import {
  Logout,
  NewCompany,
  EditCompany,
  DeleteCompany,
  Loading,
  AddButton,
  SvgLoader,
  AccessControl
} from '@/components'
import { PageCompany } from '@/helper/guied-navigation'

export default {
  components: {
    Logout,
    NewCompany,
    EditCompany,
    DeleteCompany,
    Loading,
    AddButton,
    SvgLoader,
    AccessControl
  },
  data () {
    return {
      page: 1,
      isLoading: false,
      managerNewCompany: false,
      managerEditCompany: false,
      managerDeleteCompany: false,
      managerAccessControl: false,
      company: {},
      search: '',
      compact: false,
      min: false,
      tour: PageCompany
    }
  },
  created () {
    const sizeCard = JSON.parse(window.localStorage.getItem('size-card-company'))
    if (sizeCard) {
      this.compact = sizeCard.compact
      this.min = sizeCard.min
    } else {
      window.localStorage.setItem('size-card-company', JSON.stringify({ compact: false, min: false }))
    }
  },
  mounted () {
    this.CLEAR_BOTS_STORE()
    this.isLoading = true
    this.getCompanies(false)
    // if (window.localStorage.getItem('vuex-people4manager') && !JSON.parse(window.localStorage.getItem('vuex-people4manager')).Account.account.passedTutorial) {
    //   this.$actionNavigationStep(this.tour)
    //   // TODO: remover quando for implementar o tutorial
    //   this.$actionStartGuideNavigation(false)
    // }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.$firebaseAuth().currentUser || to.name === 'Bots') {
      next()
    } else {
      next({ name: 'Companies' })
    }
  },
  computed: {
    ...mapGetters([
      'getterCompanies',
      '$getterStartGuideNavigation',
      '$getterPositionStep'
    ]),
    filteredCompanies () {
      return !!this.search && this.getterCompanies ? this.getterCompanies.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase())) : this.getterCompanies
    }
  },
  methods: {
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    ...mapActions([
      'actionGetAllCompanies',
      '$actionNavigationStep',
      '$actionStartGuideNavigation',
      '$actionNextStepNavigation',
      '$actionNextPage'
    ]),
    ...mapMutations([
      'SET_SELECTED_COMPANY',
      'CLEAR_BOTS_STORE'
    ]),
    openNewCompany () {
      this.managerNewCompany = !this.managerNewCompany
      // this.$actionNextStepNavigation()
    },
    callbackModals (variableToClose) {
      this[variableToClose] = false
    },
    getCompanies (concat) {
      const size = 1000

      if (this.page > 1) {
        this.isLoading = true
      }

      this.actionGetAllCompanies({ page: this.page, size, concat })
        .then(() => {
          this.isLoading = false
        })
        .catch((err) => {
          if (err && err.response.status !== 401 && err.response.status !== 403) {
            this.addToast({
              text: this.$t('company.toast.errorSearchCompany'),
              type: 'danger'
            })
          }

          if (err && err.response.status === 403 && err.response.data === 'the account is not associated with any company') {
            this.$eventBus.$emit('signOut')
          }

          this.isLoading = false
        })
    },
    openModalEditCompany (company) {
      if (!this.$getterStartGuideNavigation) {
        this.SET_SELECTED_COMPANY(company)
        this.managerEditCompany = true
      }
    },
    openModalDeleteCompany (company) {
      if (!this.$getterStartGuideNavigation) {
        this.SET_SELECTED_COMPANY(company)
        this.managerDeleteCompany = true
      }
    },
    goToBots (company) {
      // eslint-disable-next-line
      !this.$getterStartGuideNavigation || this.$getterPositionStep === 5 ? this.nextSelectedBotNormal(company) : null
    },
    nextSelectedBotNormal (company) {
      this.$actionStartGuideNavigation(false)
      // this.$actionNextPage(true)
      this.SET_SELECTED_COMPANY(company)
      this.$router.push({ name: 'Bots' })
    },
    openModalAccessControl (company) {
      if (!this.$getterStartGuideNavigation) {
        this.SET_SELECTED_COMPANY(company)
        this.managerAccessControl = true
      }
    },
    checkGoToBots (company) {
      if (this.min) {
        this.goToBots(company)
      }
    },
    changeSizeCard (compact, min) {
      this.compact = compact
      this.min = min
      window.localStorage.setItem('size-card-company', JSON.stringify({ compact: this.compact, min: this.min }))
    },
    reloadData () {
      this.page = 1
      this.getCompanies(false)
    }
  }
}
